<template>
  <div class='element materialShadow profile-box'>
    <h2 class='headline'>{{ x('profileMasterData') }}:</h2>
    <dl class="input_list">
      <dt>{{ x('firstName') }}:</dt>
      <dd><label>
        <input type="text" v-model="user.firstName">
      </label></dd>
      <dt>{{ x('lastName') }}:</dt>
      <dd><label>
        <input type="text" v-model="user.lastName">
      </label></dd>
      <dt>{{ x('email') }}:</dt>
      <dd><label>
        <input type="email" v-model="user.email">
      </label></dd>
      <dt>{{ x('phone') }}:</dt>
      <dd><label>
        <input type="tel" v-model="user.phone">
      </label></dd>
      <dt>{{ x('Users.directToCamera') }}</dt>
      <dd><label>
        <input type="checkbox" v-model="user.directToCamera">
      </label></dd>
      <dd>
        <div v-if="errorMessage!==null" class="password_error_string">{{ errorMessage }}</div>
        <button type="submit" class="lsc-button lsc-rounded-10 green fullWidth"
                @click="saveChanges">
          <template v-if="isSaving">
            <icon name="fa-cog fa-spin"/>{{ x('savingDots') }}
          </template>
          <template v-else-if="changeSucceeded">
            <icon name='fa-check'/>{{ x('changesSaved') }}
          </template>
          <template v-else>
            <icon name="fa-upload"/>{{ x('save') }}
          </template>
        </button>
      </dd>
      <dd v-if="config.obj_texts.supportButtonEnabled">
        <button class="lsc-button lsc-rounded-10 fullWidth"
                @click="sendSupportInformation">{{ x('sendSupportInformation') }}</button>
      </dd>
    </dl>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import translate from '../Mixins/Translate';
  import { httpPost, httpPut } from '../../classes/httpHelper';
  import getEnvironment from '../Mixins/GetEnvironment';

  export default {
    name: 'personal-details-section',
    mixins: [translate, getEnvironment],
    data() {
      return {
        errorMessage: null,
        isSaving: false,
        changeSucceeded: false,
        originalFirstName: this.$store.state.user.firstName,
        originalLastName: this.$store.state.user.lastName,
        originalEmail: this.$store.state.user.email,
        originalPhone: this.$store.state.user.phone,
        originalDirectToCamera: this.$store.state.user.directToCamera,
      };
    },
    computed: mapState(['config', 'user']),
    methods: {
      async saveChanges() {
        this.errorMessage = null;
        this.isSaving = true;
        this.changeSucceeded = false;

        const data = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          phone: this.user.phone,
          directToCamera: this.user.directToCamera,
        };

        this.$store.commit('setConfigProperties', { directToCamera: this.user.directToCamera });

        try {
          const response = await httpPut('users/me', data);

          console.log('Received server response: ', response);
          this.isSaving = false;
          this.changeSucceeded = true;
        } catch (e) {
          this.errorMessage = this.x('errorOccurredTryAgain');
          this.changeSucceeded = false;
          this.isSavingPassword = false;
        }
      },
      async sendSupportInformation() {
        await this.$store.dispatch('LocationStore/requestLocation');
        await this.$nextTick();
        const platformInfo = {
          platformInfo: this.getEnvironment(),
        };
        console.log('platformInfo', platformInfo);
        const response = await httpPost('users/platform', platformInfo);
        console.log('Response:', response);
        // eslint-disable-next-line no-alert
        window.alert(`${this.x('sendSupportInformationConfirm')}

${this.getEnvironment()}`);
      },
    },
  };
</script>

<style scoped lang="scss">
.profile-box {
  h2 {

    &.headline {
      color: var(--ThemeProfileBoxHeadline) !important;
    }
  }
}
</style>
