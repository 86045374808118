<template>
  <div class='scrollList' id='${returnID}'>
    <user-stats/>
    <div class="lsc-clear"/>
    <password-section/>
    <language-section/>
    <personal-details-section/>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import UserStats from './UserStats';
  import PasswordSection from './PasswordSection';
  import LanguageSection from './LanguageSection';
  import PersonalDetailsSection from './PersonalDetailsSection';
  import Login from '../../classes/login';

  export default {
    name: 'user-profile-frame',
    components: {
      'user-stats': UserStats,
      'password-section': PasswordSection,
      'language-section': LanguageSection,
      'personal-details-section': PersonalDetailsSection,
    },
    computed: mapState(['config', 'user']),
    async mounted() {
      // The user object retrieved at login is reduced, it does not have all user information.
      // So we need to check if the full object is there. If not, we should load it.
      if (!this.user || !this.user.isFullObject) {
        console.warn('Loading user data');
        const user = await Login.LoadUser();
        this.$store.commit('setUserData', user);
      }
    },
  };
</script>
