<template>
  <div class='element materialShadow profile-box'>
    <h2 class='headline'>{{ x('logIn') }}:</h2>
    <form @submit.prevent="saveLoginInfo()">
      <dl class="input_list">
        <dt>{{ x('username') }}:</dt>
        <dd><input type="text" autocomplete="username" :value="user.username" name="username"
                   readonly></dd>
        <dt>{{ x('oldPassword') }}:</dt>
        <dd>
          <label>
            <input type="password" v-model="oldPassword" name="old_password"
                   autocomplete="current-password"
                   :placeholder="x('passwordDots')">
          </label>
        </dd>
        <dt>{{ x('newPassword') }}:</dt>
        <dd>
          <label>
            <input type="password" v-model="newPassword" name="new_password"
                   autocomplete="new-password"
                   :placeholder="x('passwordDots')">
          </label>
        </dd>
        <dt>{{ x('repeatNewPassword') }}:</dt>
        <dd>
          <label>
            <input type="password" v-model="repeatPassword" name="repeat_password"
                   autocomplete="new-password"
                   :placeholder="x('repeatNewPasswordDots')">
          </label>
        </dd>
        <dd>
          <div v-if="errorMessage!==null" class="password_error_string">{{ errorMessage }}</div>
          <button type="submit" class="lsc-button lsc-rounded-10 green fullWidth"
                  id="b_save_login_info"
                  @click="saveLoginInfo">
            <template v-if="isSavingPassword">
              <i class='fa fa-cog fa-spin'></i>{{ x('savingDots') }}
            </template>
            <template v-else-if="changeSucceeded">
              <i class='fa fa-check'></i>{{ x('newPasswordSaved') }}
            </template>
            <template v-else>
              <i class="fa fa-upload"></i>{{ x('changePassword') }}
            </template>
          </button>
        </dd>
      </dl>
    </form>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpPut } from '../../classes/httpHelper';
  import translation from '../Mixins/Translate';

  export default {
    name: 'password-section',
    mixins: [translation],
    data() {
      return {
        errorMessage: null,
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
        isSavingPassword: false,
        changeSucceeded: false,
      };
    },
    computed: mapState(['config', 'user']),
    methods: {
      async saveLoginInfo() {
        console.log('save_login_info');
        this.errorMessage = null;
        this.isSavingPassword = true;
        this.changeSucceeded = false;

        if (this.oldPassword === '') {
          this.errorMessage = this.x('oldPasswordMissing');
          this.isSavingPassword = false;
          return;
        }

        if (this.newPassword === '') {
          this.errorMessage = this.x('newPasswordMissing');
          this.isSavingPassword = false;
          return;
        }

        if (this.newPassword !== this.repeatPassword) {
          this.errorMessage = this.x('newPasswordMismatch');
          this.isSavingPassword = false;
          return;
        }

        const data = JSON.stringify({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });

        try {
          const response = await httpPut('users/me/password', data);

          console.log('Received server response: ', response);
          this.isSavingPassword = false;
          if (response.ok) {
            this.changeSucceeded = true;
            this.oldPassword = '';
            this.newPassword = '';
            this.repeatPassword = '';
          } else {
            this.errorMessage = this.x('oldPasswordWrong');
          }
        } catch (e) {
          this.errorMessage = this.x('errorOccurredTryAgain');
          this.changeSucceeded = false;
          this.isSavingPassword = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .password_error_string {
    display: block;
  }

  input {
    width: 100%;
  }

  .profile-box {
    min-height: 265px;

    h2 {

      &.headline {
      color: var(--ThemeProfileBoxHeadline) !important;
      }
    }
  }
</style>
