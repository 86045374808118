<template>
  <ul class="statContainer">
    <li v-if="config.features[featureNames.likes]" class="element small_ico">
      <i class="fa fa-thumbs-o-up"></i><i class="fa fa-chevron-right arrow"></i><i
      class="fa fa-user user"></i><br>
      <span>{{ x('likes') }}<br>
        <b class="Likes">
          <i v-if="stats.likes==null" class="fa fa-cog fa-spin"></i>
          <template v-else>{{ stats.likes }}</template>
        </b>
      </span>
    </li>
    <li class="element">
      <i class="fa fa-upload"></i><br>
      <span>{{ x('uploads') }}<br>
        <b class="Uploads">
          <i v-if="stats.visits==null" class="fa fa-cog fa-spin"></i>
          <template v-else>{{ stats.visits }}</template>
        </b>
      </span>
    </li>
    <li v-if="config.features[featureNames.likes]" class="element small_ico">
      <i class="fa fa-user user"></i><i class="fa fa-chevron-right arrow"></i><i
      class="fa fa-thumbs-o-up"></i><br>
      <span>{{ x('liked') }}<br>
        <b class="Liked">
          <i v-if="stats.liked==null" class="fa fa-cog fa-spin"></i>
          <template v-else>{{ stats.liked }}</template>
        </b>
      </span>
    </li>
    <li v-if="config.features[featureNames.comments]" class="element">
      <i class="fa fa-commenting"></i>
      <span>{{ x('comments') }}<br>
        <b class="Comments">
          <i v-if="stats.comments==null" class="fa fa-cog fa-spin"></i>
          <template v-else>{{ stats.comments }}</template>
        </b>
      </span>
    </li>
  </ul>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpGet } from '../../classes/httpHelper';
  import translate from '../Mixins/Translate';
  import featureNames from '../../enums/feature-names';

  export default {
    name: 'user-stats',
    mixins: [translate],
    data() {
      return {
        stats: {
          likes: null,
          visits: null,
          liked: null,
          comments: null,
        },
        featureNames,
      };
    },
    computed: mapState(['config', 'user']),
    async mounted() {
      this.stats = await httpGet(`users/me/stats?userrole=${this.user.userRole}`);
    },
  };
</script>
