<template>
  <div class='element materialShadow profile-box'>
    <h2 class='headline'>{{x('Users.language')}}</h2>
    <dl class="input_list">
      <dt>{{ x('Users.language') }}:</dt>
      <dd><label>
        <select v-model="user.language">
          <option disabled value="">{{x('select')}}</option>
          <option v-for="language in languages" :value="language.choice" :key="language.choice">
            {{language.niceName}}
          </option>
        </select>
      </label></dd>
      <dd>
        <div v-if="errorMessage!==null" class="password_error_string">{{ errorMessage }}</div>
        <button type="submit" class="lsc-button lsc-rounded-10 green fullWidth"
                @click="saveChanges">
          <template v-if="isSaving">
            <i class='fa fa-cog fa-spin'></i>{{ x('savingDots') }}
          </template>
          <template v-else-if="changeSucceeded">
            <i class='fa fa-check'></i>{{ x('changesSaved') }}
          </template>
          <template v-else>
            <i class="fa fa-upload"></i>{{ x('save') }}
          </template>
        </button>
      </dd>
    </dl>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import translate from '../Mixins/Translate';
  import { httpGet, httpPut } from '../../classes/httpHelper';

  export default {
    name: 'language-section',
    mixins: [translate],
    data() {
      return {
        languages: [],
        errorMessage: null,
        isSaving: false,
        changeSucceeded: false,
      };
    },
    computed: mapState(['config', 'user']),
    async created() {
      await this.getLanguages();
    },
    methods: {
      async getLanguages() {
        this.languages = await httpGet('choices/Users_language_list');
      },
      async saveChanges() {
        this.errorMessage = null;
        this.isSaving = true;
        this.changeSucceeded = false;

        const data = {
          language: this.user.language,
        };

        try {
          const response = await httpPut('users/me', data);

          console.log('Received server response: ', response);
          await window.bootstrap.getTranslation(this.user.language);
          this.isSaving = false;
          this.changeSucceeded = true;
        } catch (e) {
          this.errorMessage = this.x('errorOccurredTryAgain');
          this.changeSucceeded = false;
          this.isSavingPassword = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .profile-box {
    h2 {

      &.headline {
      color: var(--ThemeProfileBoxHeadline) !important;
    }
  }
}
</style>
